<template>
  
  <v-container fluid>
    <v-tabs
          v-model="tab"
          fixed-tabs
          @change="change_tab()"
        >
          <v-tab :disabled="tab_info.processed.file_list.length==0">Success ({{tab_info.processed.file_list.length}})
            </v-tab>
            <v-tab :disabled="tab_info.error.file_list.length==0">Error ({{tab_info.error.file_list.length}})
            </v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item>
    <v-row class="fill-height">
      <!-- Top/left part -->
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="scroll-y" style="height: 30vh; overflow-y: auto;" 
      v-if="order_array_top.length + order_array_normal.length > 0">
        <v-list-item-group v-model="model">
            <v-list-subheader v-if="order_array_top.length > 0">BEST MATCH</v-list-subheader>
          <v-list-item v-for="(order, order_id) in order_array_top" :key="order_id" class="yellow">

            <v-list-item-content v-if="order.order.group_name!=undefined">
                <v-list-item-title>
                    Group: {{ order.order.group_name }} {{ products_ready_stat(order.order) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ order.order.client_firstname }} {{ order.order.client_lastname }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-else-if="order.order.gift_name!=undefined">
                <v-list-item-title>
                    Gift: {{ order.order.gift_name }} {{ products_ready_stat(order.order) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ order.order.client_firstname }} {{ order.order.client_lastname }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>{{ order.order.client_firstname }} {{ order.order.client_lastname }} {{ products_ready_stat(order.order) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <div v-if="processing_order == null || order.order.id!=processing_order.order.id">
                <v-btn icon @click="select_customer(order)" :disabled="processing_order!=null">
                    <v-icon>mdi-check-outline</v-icon>
                </v-btn>
                </div>
                <div v-else>
                    <v-progress-circular
                    indeterminate
                    color="primary"
                    ></v-progress-circular>
                </div>                
            </v-list-item-action>
          </v-list-item>
          <v-list-subheader v-if="order_array_top.length > 0 && order_array_normal.length > 0">NORMAL MATCH</v-list-subheader>
          <v-list-item v-for="order in order_array_normal" :key="order.order.id" class="grey lighten-4">
            <v-list-item-content v-if="order.order.group_name!=undefined">
                <v-list-item-title>
                    Group: {{ order.order.group_name }} {{ products_ready_stat(order.order) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ order.order.client_firstname }} {{ order.order.client_lastname }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-else-if="order.order.gift_name!=undefined">
                <v-list-item-title>
                    Gift: {{ order.order.gift_name }} {{ products_ready_stat(order.order) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ order.order.client_firstname }} {{ order.order.client_lastname }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>{{ order.order.client_firstname }} {{ order.order.client_lastname }} {{ products_ready_stat(order.order) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <div v-if="processing_order == null || order.order.id!=processing_order.order.id">
                <v-btn icon @click="select_customer(order)" :disabled="processing_order!=null">
                    <v-icon>mdi-check-outline</v-icon>
                </v-btn>
                </div>
                <div v-else>
                    <v-progress-circular
                    indeterminate
                    color="primary"
                    ></v-progress-circular>
                </div>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="scroll-y" style="height: 40vh; overflow-y: auto;" v-else>
        No order found for this ticket
      </v-col>

      <!-- Bottom/right part -->
      <v-col v-if="tab_info.processed!=null" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="scroll-y" style="height: 50vh; overflow-y: auto;">
        <v-sheet width="100%" class="d-flex flex-grow-1" v-if="tab_info.processed.file_list.length > 0">
          <v-img :src="tab_info.processed.file_list[tab_info.processed.image_index].downloadURL" height="100%" contain />
        </v-sheet>
        <!-- {{ file_list[image_index].ticket_info }} -->
      </v-col>
    </v-row>
  </v-window-item>

      <v-window-item>
        <v-row class="fill-height" v-if="tab_info.error!=null">
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="scroll-y" style="height: 75vh; overflow-y: auto;">
        <v-sheet width="100%" class="d-flex flex-grow-1" v-if="tab_info.error.file_list.length > 0">
          <v-img :src="tab_info.error.file_list[tab_info.error.image_index]?.downloadURL" height="100%" contain />
        </v-sheet>
        <!-- {{ file_list[image_index].ticket_info }} -->
      </v-col>
        </v-row>
        </v-window-item>

  </v-window>
    <emb-delete-confirmation
            ref="deleteConfirmationDialog"
            message="Are you sure you want to delete this ticket?"
            :processing="deleting_file"
            @onConfirm="on_delete_ticket"
          >
    </emb-delete-confirmation>

    <!-- Action bar at bottom -->
    <v-bottom-navigation class="mt-8" style="position: fixed; bottom: 0;">
      <v-btn icon @click="change_index(1)" :disabled="this.selected_tab_info?.image_index >= this.selected_tab_info?.file_list.length-1">
        Next
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
      <v-btn icon @click="change_index(-1)" :disabled="selected_tab_info?.image_index==0">
        Previous
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
      <v-btn icon @click="delete_ticket()">
        Delete
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-menu v-model="menuVisible">
      <template v-slot:activator="{ props }">
        <v-btn icon v-bind="props" @click="menuVisible=true">
          More
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="display_debug_info()">
          <v-list-item-title>Debug info</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
      <!-- <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-bottom-navigation>
    <v-dialog v-model="show_debug_dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Debug info</span>
        </v-card-title>

        <v-card-text>
          <v-textarea v-model="debug_text" :rows="10"></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="show_debug_dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      multi-line
      color="red"
    >
      Error, try other or contact admin.
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>

</template>

<script>
import { mapGetters } from "vuex";
import { getStorage, ref, listAll, getMetadata, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  setDoc,
  doc,
} from "firebase/firestore";
import * as moment from "moment";

export default {
  computed: {
    ...mapGetters([]),
  },
  data() {
    return {
        snackbar: false,
        menuVisible: false,
        show_debug_dialog: false,
        debug_text: "",
        tab: null,
        model: null,
        top_color_toggle: true,
        normal_color_toggle: true,
        selected_tab_info: null,
        tab_info: {
          processed: {
            image_index:0,
            file_list: [],
            folder: "scanner_processed/",
          },
          error: {
            image_index:0,
            file_list: [],
            folder: "scanner_error/",
          }
        },
        assigned_order_list: [],
        order_list_top: [],
        order_list_normal: [],
        order_array_top: [],
        order_array_normal: [],
        ticket_list: [],
        processing_order: null,
        deleting_file: false,
    };
  },

  mounted() {
    this.selected_tab_info = this.tab_info.processed;
    this.fetch_assigned_orders();
    this.get_tickets(this.tab_info.processed);
    this.get_tickets(this.tab_info.error);

  },

  methods: {
    async fetch_assigned_orders() {
      // Create a query for documents in the 'orders' collection with status 'ASSIGNED' and order them by 'order_date' ascending
      const q = query(
        collection(getFirestore(), "orders"),
        where("status", "==", "ASSIGNED"),
        orderBy("order_date", "asc")
      );

      // Execute the query and retrieve the resulting documents
      var querySnapshot = await getDocs(q)
      var assignedDocs = [];
      for (var doc of querySnapshot.docs) {
        assignedDocs.push(doc.data());
      }
      this.assigned_order_list = assignedDocs;
    //   console.log("order list: ", this.assigned_order_list);

    },
    convertGridsToStringArray(grids) {
      var result = [];
      for (var play of grids) {
        // var play_str = play.pool[0].numbers.join("-");

        var play_str = play.pool[0].numbers
          .map((number) => {
            return number < 10 ? `0${number}` : `${number}`;
          })
          .join("-");

        play_str += ":";
        //play_str += play.pool[1].numbers.join("-");
        play_str += play.pool[1].numbers
          .map((number) => {
            return number < 10 ? `0${number}` : `${number}`;
          })
          .join("-");
        result.push(play_str);
      }
      return result;
    },
    findUserOrder(ticket) {
        var result_top = {};
        var result_normal = {};
      for (var order of this.assigned_order_list) {
        for (var product_index in order.products) {
          var product = order.products[parseInt(product_index)];
          if (product.ready != undefined && product.ready == true) {
            continue;
          }
          // Multi play check
          if (
            (product.ticket.multiplay > 1 && ticket.draw_dates.length == 1) ||
            (product.ticket.multiplay == 1 && ticket.draw_dates.length > 1)
          ) {
            continue;
          }

          // Multiplier check
          if (product.ticket.multiplier != ticket.multiplier) {
            continue;
          }

          if (product.ticket.type_name != ticket.type) {
            continue;
          }

          // Get the number of plays
          var grids_count = 0;
          if (product.ticket.grids != undefined) {
            grids_count = product.ticket.grids.length;
          } else {
            grids_count = product.ticket.quickPicks;
          }

          if (grids_count != ticket.grids.length) {
            continue;
          }

          // Check if the ticket is for a grid order
          if (product.ticket.grids != undefined) {
            var order_grids = this.convertGridsToStringArray(
              product.ticket.grids
            );
            var play_count = 0;
            for (var ticket_grid of ticket.grids) {
              if (order_grids.includes(ticket_grid)) {
                play_count++;
              }
            }

            if (play_count == ticket.grids.length) {
              // If the ticket fit with a grid order,
              // Add the user in the top list
              result_top[order.id] = {
                order: order,
                product_index: product_index,
                client_id: order.client_id,
              };
              if (result_normal[order.id] != undefined) {
                delete result_normal[order.id];
              }
            } else if (result_top[order.id] == undefined) {
              // If the user is not already in the top list, add it in normal
              result_normal[order.id] = {
                order: order,
                product_index: product_index,
                client_id: order.client_id,
              };
            }
          } else {
            // If the ticket is for a quick pick,
            // Add the user in the normal list
            result_normal[order.id] = {
              order: order,
              product_index: product_index,
              client_id: order.client_id,
            };
          }
        }
      }
      return [result_top, result_normal];
    },
    update_order(ticket_info) {
        [this.order_list_top, this.order_list_normal] = this.findUserOrder(ticket_info);
        this.order_array_top = this.convert_list_to_array(this.order_list_top);
        this.order_array_normal = this.convert_list_to_array(this.order_list_normal);
    },
    select_ticket(index) {
        if (index < 0) {
            index = 0;
        } else if (index >= this.selected_tab_info.file_list.length) {
            index = this.selected_tab_info.file_list.length - 1;
        }
        this.selected_tab_info.image_index = index;
        if (this.selected_tab_info.file_list.length > 0) {
          this.update_order(this.selected_tab_info.file_list[index].ticket_info);
        }

    },
    async get_tickets(tab_info) {
        const storage = getStorage();

        // Get a reference to the folder
        const folderRef = ref(storage, tab_info.folder);

        // Call listAll() to get a list of all items (files and sub-folders) in the folder
        var res = await listAll(folderRef);
        // .then((res) => {
        // result_list = [];
        tab_info.file_list.splice(0, tab_info.file_list.length);
        // Loop through the items in the folder
        // res.items.forEach((itemRef) => {
        for (var itemRef of res.items) {
        // Get the metadata for the item
          var metadata = await getMetadata(itemRef);
            // .then((metadata) => {
          // convert metadata.customMetadata.ticket_info to JSON
          var ticket_info = JSON.parse(metadata.customMetadata.ticket_info);
          var ocr_text = JSON.parse(metadata.customMetadata.orc_text);
          // Get download URL for file
          var url = await getDownloadURL(itemRef);
          // .then((url) => {
        
          // Add the metadata and download URL to the file list
          tab_info.file_list.push({
              name: metadata.name,
              fullPath: metadata.fullPath,
              contentType: metadata.contentType,
              size: metadata.size,
              downloadURL: url,
              ticket_info: ticket_info,
              ocr_text: ocr_text,
          });


          this.select_ticket(0);
        }

    },
    convert_list_to_array(orders) {
        var result = [];
        for (var order_id in orders) {
            result.push(orders[order_id]);
        }
        // Sort the array by order.client_firstname
        result.sort(function(a, b) {
            var nameA = a.order.client_firstname.toUpperCase(); // ignore upper and lowercase
            var nameB = b.order.client_firstname.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
            return -1;
            }
            if (nameA > nameB) {
            return 1;
            }

            // names must be equal
            return 0;
        });
        return result;
    },
    products_ready_stat(order) {
      let count = 0;
      order.products.forEach((product) => {
        if (product.ready) {
          count++;
        }
      });
      return "(" + count + "/" + order.products.length + ")";
    },
    // Convert grids from format 01-02-03-04-05:06 to 01-02-03-04-05 (06)
    convertGrids(grids) {
      var new_grids = [];
      for (var grid of grids) {
        var new_grid = grid.replace(":", " (") + ")";
        new_grids.push({
          error: "",
          play: new_grid,
        });
      }
      return new_grids;
    },   
    async select_customer(selected_item) {
      try{
        this.processing_order = selected_item;
        var selected_ticket = this.selected_tab_info.file_list[this.selected_tab_info.image_index]
        
        var scan = {
            first_draw_date: moment(selected_ticket.ticket_info.first_draw_date).toDate(),
            last_draw_date: moment(selected_ticket.ticket_info.last_draw_date).toDate(),
            grids: this.convertGrids(selected_ticket.ticket_info.grids),
            multiplier: selected_ticket.ticket_info.multiplier,
            image: selected_ticket.downloadURL,
            path: selected_ticket.fullPath,
            draw_dates: selected_ticket.ticket_info.draw_dates,
        };

        var product = selected_item.order.products[selected_item.product_index];
        var order = selected_item.order;
        product.scan = scan;
        product.ready = true;

        var file_info = {
            product_index: selected_item.product_index,
            source: product.scan.path,
        };

        const move_file_info = {
            order_id: selected_item.order.id,
            files_info: [file_info],
        };
        // Move file if all products are completed
        const moveFileFunc = httpsCallable(getFunctions(), "moveFile");
        var response = await moveFileFunc({move_file_info})
        var file_destination = response.data.data[selected_item.product_index];
        order.products[selected_item.product_index].scan.path = file_destination;
        order.products[selected_item.product_index].scan.image = "";       
        
        var all_ready = true;
        for (var ready_product of order.products) {
          if (ready_product.ready == undefined || ready_product.ready == false) {
            all_ready = false;
            break;
          }
        }

        if (all_ready) {
          selected_item.order.status = "COMPLETED";
          selected_item.order.general_status = "COMPLETED";
          selected_item.order.update_date = new Date();
        }

        // Update the order
        await setDoc(doc(getFirestore(), "orders", order.id), order);
        await this.fetch_assigned_orders();
        this.get_tickets(this.tab_info.processed);
  
      } catch (err) {
        console.log("Error: ", err);
        this.snackbar = true;
      }
      this.progress = false;
      this.processing_order = null;
    },
    delete_ticket() {
      this.deleting_file = false;
      this.$refs.deleteConfirmationDialog.openDialog();
    },
    on_delete_ticket() {
      this.deleting_file = true;
      const file_source = this.selected_tab_info.file_list[this.selected_tab_info.image_index].fullPath;

      const file_splitted = file_source.split('/')
      file_splitted[0] = "scanner_deleted";
      const file_destination = file_splitted.join('/');

      var move_file_info = {
        source: file_source,
        destination: file_destination,
      }

      // Move file to deleted folder to analyse later
      const moveFileFunc = httpsCallable(getFunctions(), "moveSingleFile");
            moveFileFunc({
            move_file_info,
            })
            .then(() => {
              this.selected_tab_info.file_list.splice(this.selected_tab_info.image_index, 1);
              if (this.selected_tab_info.image_index > this.selected_tab_info.file_list.length - 1) {
                this.selected_tab_info.image_index = this.selected_tab_info.file_list.length - 1;
              }
              this.select_ticket(this.selected_tab_info.image_index);
              this.$refs.deleteConfirmationDialog.close();
              this.deleting_file = false;
            }).catch((error) => {
              console.error(error);
              this.$refs.deleteConfirmationDialog.close();
              this.deleting_file = false;
            });
      
    },
    change_index(value) {
      this.selected_tab_info.image_index += value;
      if (this.selected_tab_info.image_index < 0) {
        this.selected_tab_info.image_index = 0;
      } else if (this.selected_tab_info.image_index >= this.selected_tab_info.file_list.length) {
        this.selected_tab_info.image_index = this.selected_tab_info.file_list.length-1;
      }

      this.select_ticket(this.selected_tab_info.image_index);
    },
    display_debug_info() {
      const selected_ticket = this.selected_tab_info.file_list[this.selected_tab_info.image_index];
      // Generate text 
      var text = "Image Info\n==========";
      text += "\ndownload url: " +  selected_ticket.downloadURL;
      text += "\nfull path: " +  selected_ticket.fullPath;
      text += "\nsize: " +  parseInt(selected_ticket.size);
      text += "\n\nTicket Info";
      text += "\n===========\n"
      text += JSON.stringify(selected_ticket.ticket_info, null, 2);
      text += "\n\nOCR Text\n========\n";
      text += JSON.stringify(selected_ticket.ocr_text, null, 2);
      this.debug_text = text;

      this.show_debug_dialog = true;

    },
    change_tab() {
      if (this.tab == 0) {
        // Processed tab
        this.selected_tab_info = this.tab_info.processed;
      } else {
        // Error tab
        this.selected_tab_info = this.tab_info.error;
      }
      this.select_ticket(this.selected_tab_info?.image_index);
    }
  },
};
</script>